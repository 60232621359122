<template>
  <div>
      <v-alert type="success" v-if="saved" dismissible>
          Your changes have been saved.
      </v-alert>
      <v-card>
          <v-card-title>Your Profile</v-card-title>
          <v-card-text>
              <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="companyName"
                    :rules="rules.text"
                    label="Company Name"
                    hint="Your company's legal entity name will be displayed in our in-context app with a link to your Terms and Conditions."
                    persistent-hint
                    required
                    class="mb-4"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="terms"
                    :rules="rules.url"
                    label="Terms and Conditions Link"
                    hint="The full URL to your Terms & Conditions page, for example: https://www.example.com/terms"
                    persistent-hint
                    required
                    class="mb-4"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="contactUrl"
                    :rules="rules.url"
                    label="Contact Link"
                    hint="The full URL to your Contact Us page, for example: https://www.example.com/contact-us"
                    persistent-hint
                    required
                    class="mb-4"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="brandName"
                    :rules="rules.text"
                    label="Brand Name"
                    hint="This will be displayed in our in-context app next to your brand logo."
                    persistent-hint
                    required
                    class="mb-4"
                    outlined
                  ></v-text-field>

                  <v-row no-gutters>
                      <v-col cols="1" v-if="logo">
                          <img v-bind:src="logo" width="75" height="75" alt="Uploaded Brand Logo" />
                      </v-col>
                      <v-col v-bind:cols="logo ? 11 : 12">
                          <v-file-input
                            v-model="newLogo"
                            label="Brand Logo"
                            outlined
                            hint="Upload an JPG or PNG image of your brand logo with a width and height of 250 pixels"
                            persistent-hint
                            class="mb-4"
                            prepend-icon=""
                            prepend-inner-icon="mdi-paperclip"
                            accept=".png,.jpg"
                          >
                          </v-file-input>
                      </v-col>
                  </v-row>
                  <v-text-field
                    v-model="freeDeliveryText"
                    label="Free Delivery Text"
                    hint="Short promotional text to let customers know about Free Delivery ie.'Free Shipping over £100.00'"
                    persistent-hint
                    required
                    class="mb-4"
                    outlined
                  ></v-text-field>
                <v-textarea
                  v-model="delivery"
                  label="Delivery & Returns Information"
                  hint="Information about your delivery and returns, displayed to customers"
                  persistent-hint
                  required
                  class="mb-10"
                  outlined
                ></v-textarea>
                <v-textarea
                  v-model="footerText"
                  label="Footer Text"
                  hint="Useful section to add any legal copy, disclaimers, copyrights."
                  persistent-hint
                  required
                  class="mb-10"
                  outlined
                ></v-textarea>
                  <v-btn color="primary" @click="submit" v-bind:loading="saving">
                      Save Profile
                  </v-btn>
              </v-form>
          </v-card-text>
      </v-card>
  </div>
</template>


<script>
import api from './../helpers/Api'

export default {
    data: () => ({
        valid: false,
        rules: {
            url: [
                v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(v) || 'Please enter a valid URL starting with https://'
            ],
            text: [
                v => !!v || 'Please enter a value'
            ]
        },
        companyName: '',
        contactUrl: '',
        brandName: '',
        delivery: '',
        freeDeliveryText: '',
        footerText: '',
        terms: '',
        logo: '',
        newLogo: null,
        saving: false,
        saved: false
    }),
    methods: {
        submit () {
            this.$refs.form.validate();
            if (!this.valid) return;
            this.saving = true;

            let formData = new FormData();
            formData.append("name", this.brandName);
            formData.append("company_name", this.companyName);
            formData.append("contact_url", this.contactUrl);
            formData.append("delivery", this.delivery);
            formData.append("terms", this.terms);
            formData.append("new_logo", this.newLogo);
            formData.append("free_delivery_text", this.freeDeliveryText);
            formData.append("footer_text", this.footerText);

            api.saveProfile(formData).then(response => {
                this.saved = true;
            }).catch(error => {
                this.saved = false;
                this.$store.dispatch('showErrorMessage', "We were unable to save your changes. Please try again.");
            }).finally(() => {
                this.saving = false;
            });
        }
    },
    mounted() {
        api.getProfile().then(response => {
            this.companyName = response.data.company_name;
            this.contactUrl = response.data.contact_url;
            this.brandName = response.data.name;
            this.terms = response.data.terms;
            this.logo = response.data.logo;
            this.delivery = response.data.delivery;
            this.freeDeliveryText = response.data.freeDeliveryText;
            this.footerText = response.data.footerText;
        });
    }
}
</script>
