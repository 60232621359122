<template>
  <div>
    <v-data-table
      :footer-props="{'items-per-page-options': []}"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      loading-text="Retrieving Products..."
      no-data-text="No Products Found"
      disable-sort
      class="elevation-1"
    >
     <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Products</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
          ></v-text-field>
      </v-toolbar>
     </template>
     <template v-slot:item.enabled="{ item }">
       <v-chip v-if="item.enabled" class="ma-2" color="green" text-color="white">Yes</v-chip>
       <v-chip v-else class="ma-2">No</v-chip>
     </template>
     <template v-slot:item.commission="{ item }">
       {{ item.commission.rate }}%
     </template>
    </v-data-table>
  </div>
</template>


<script>
import api from './../helpers/Api'

export default {
data () {
      return {
        searchInterval: null,
        search: '',
        total: 0,
        items: [],
        loading: true,
        options: {},
        headers: [
          {
            text: 'Identifier',
            align: 'left',
            sortable: false,
            value: 'advertiser_identifier',
          },
          { text: 'Name', value: 'name' },
          { text: 'Enabled', value: 'enabled' },
          { text: 'Commission Rate', value: 'commission' },
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
      search: function(val) {
          if (this.searchInterval) {
              clearTimeout(this.searchInterval);
          }

          this.searchInterval = setTimeout(() => {
              this.loading = true;
              this.getDataFromApi();
          }, 500);
      }
    },
    methods: {
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
            api.getProductList(page, this.search).then(response => {
                this.items = response.data.data;
                this.total = response.data.total;
                resolve();
            }).catch(error => {
                this.items = [];
                reject();
            }).finally(response => {
                this.loading = false;
            });
        })
      },
    }
  }
</script>
