import store from './../store/index'

const axios = require('axios');
const APIURL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'https://smartcheckoutapi.gene.co.uk';

function __get(uri) {
  return axios.get(
      APIURL + uri,
      {
          headers: {
              Authorization: 'Bearer ' + store.getters.rawJwtToken
          }
      }
  )
}

function __post(uri, fields) {
    return axios.post(
        APIURL + uri,
        fields,
        {
            headers: {
                Authorization: 'Bearer ' + store.getters.rawJwtToken
            }
        }
    )
}

export default {
    auth: (email, password) => {
        return axios.post(
            APIURL + '/auth/generate',
            {email: email, password: password}
        );
    },

    getOrdersList: (page) => {
        return __get('/order/browse/mine?page=' + (page ? page : 0));
    },
    getProductList: (page, search='') => {
        return __get('/product/browse/mine?page=' + (page ? page : 0) + '&search=' + search);
    },
    getSubscribedProductList: (id, page, search='') => {
        return __get('/product/browse/subscribed/' + id + '?page=' + (page ? page : 0) + '&search=' + search);
    },

    // DataSource
    getIntegrationList: (page) => {
        return __get('/integration/browse/mine?page=' + (page ? page : 0));
    },
    getIntegration: (id) => {
        return __get('/integration/read/' + id);
    },
    connectToIntegration: (code) => {
        return __get('/integration/connect/' + code);
    },
    deleteIntegration: (id) => {
      return __post('/integration/delete/' + id, {});
    },
    createIntegration: (type, data) => {
        return axios.post(
            APIURL + '/integration/add',
            {
                type: type,
                data: data ?? ''
            },
            {
                headers: {
                    Authorization: 'Bearer ' + store.getters.rawJwtToken
                }
            }
        )
    },

    // Advertisers
    getAdvertiserList: (page) => {
        return __get('/advertiser/browse?page=' + (page ? page : 0));
    },

    // Profile
    getProfile: () => {
        return __get('/advertiser/read/mine');
    },
    saveProfile: (fields) => {
        return axios.post(
            APIURL + '/advertiser/edit/mine',
            fields,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.rawJwtToken,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },

    // Publishers
    subscribeToAdvertiser: (id) => {
        return __post('/subscription/subscribe/' + id, {});
    },

    getProductCodeList: (page) => {
        return __get('/product/code/browse?page=' + (page ? page : 0));
    },
    getProductCode: (code) => {
        return __get('/product/code/read/' + code);
    },
    createProductCode: (productId) => {
        return __post('/product/code/add', {
                product_id: productId
            });
    },
}
