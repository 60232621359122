<template>
  <div>
      <v-alert
              prominent
              type="info"
              v-if="notSubscribed"
      >
          <v-row align="center">
              <v-col class="grow">You have not subscribed to this advertiser yet.</v-col>
              <v-col class="shrink">
                  <v-btn @click="subscribe">Subscribe</v-btn>
              </v-col>
          </v-row>
      </v-alert>

    <v-data-table
      :footer-props="{'items-per-page-options': []}"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      loading-text="Retrieving Products..."
      no-data-text="No Products Found"
      disable-sort
      class="elevation-1"
    >
     <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Products</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
          ></v-text-field>
      </v-toolbar>
     </template>
    <template v-slot:item.actions="{ item }">
        <v-icon
                small
                class="mr-2"
                @click="generateLink(item)"
        >
            mdi-link
        </v-icon>
    </template>
    </v-data-table>
  </div>
</template>


<script>
import api from './../helpers/Api'

export default {
    data () {
      return {
          search: '',
        total: 0,
        items: [],
        notSubscribed: false,
        loading: true,
        options: {},
        headers: [
          {
            text: 'Identifier',
            align: 'left',
            sortable: false,
            value: 'advertiser_identifier',
          },
          { text: 'Name', value: 'name' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
        search: function(val) {
            if (this.searchInterval) {
                clearTimeout(this.searchInterval);
            }

            this.searchInterval = setTimeout(() => {
                this.loading = true;
                this.getDataFromApi();
            }, 500);
        }
    },
    methods: {
      subscribe() {
          this.$store.dispatch('showLoadingDialog');
          api.subscribeToAdvertiser(this.$route.params.id).then(response => {
              this.notSubscribed = false;
             this.getDataFromApi();
          }).catch(error => {
              // @todo ?
          }).finally(response => {
              this.$store.dispatch('hideLoadingDialog');
          });
      },

      generateLink(row) {
          this.$store.dispatch('showLoadingDialog');
          api.createProductCode(row.id).then(response => {
              this.$router.push({ name: 'viewProductCode', params: { id: response.data.code } });
          }).catch(error => {
              if(error.response.status === 403) {
                  this.notSubscribed = true;
              } else if(error.response.status === 500) {
                  // @todo already got one
                  this.$store.dispatch('showErrorMessage', 'A link has already been created');
              } else {
                  this.$store.dispatch('showErrorMessage', 'Please try again');
              }
          }).finally(response => {
              this.$store.dispatch('hideLoadingDialog');
          });
      },

      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
            api.getSubscribedProductList(this.$route.params.id, page, this.search).then(response => {
                this.items = response.data.data;
                this.total = response.data.total;
                resolve();
            }).catch(error => {
                if(error.response.status === 403) {
                    this.notSubscribed = true;
                }
                this.items = [];
            }).finally(response => {
                this.loading = false;
            });
        })
      },
    }
  }
</script>
