import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './../store/index'
import Login from '@/views/Login.vue';
import ProductList from '@/views/ProductList.vue';
import AdvertiserList from '@/views/AdvertiserList.vue';
import IntegrationList from "../views/IntegrationList";
import OrdersList from "../views/OrdersList";
import AdvertiserProductList from "../views/AdvertiserProductList";
import LinkList from "../views/LinkList";
import AdvertiserProductCodeView from "../views/AdvertiserProductCodeView";
import AdvertiserSetup from "../views/AdvertiserSetup";
import AdvertiserEdit from "../views/AdvertiserEdit";
import Help from "../views/Help"

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Login,
    meta: {
      guestOnly: true,
      hideNav: true
    },
  },
  {
    path: '/logout',
    redirect: to => {
      store.commit('jwtToken', null);
      localStorage.removeItem('jwt');
      return '/';
    }
  },
  {
    path: '/dashboard',
    meta: {
      requiresAuth: true
    },
    redirect: to => {
      return (store.state.isAdvertiser ? '/orders' : '/links');
    }
  },
  {
    path: '/setup',
    component: AdvertiserSetup,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/products',
    component: ProductList,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/integrations',
    component: IntegrationList,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/orders',
    component: OrdersList,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/profile',
    component: AdvertiserEdit,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/help',
    component: Help,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/advertisers',
    component: AdvertiserList,
    meta: {
      requiresAuth: true
    },
  },
  {
    name: 'viewAdvertiser',
    path: '/advertiser/:id',
    component: AdvertiserProductList,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/links',
    component: LinkList,
    meta: {
      requiresAuth: true
    },
  },
  {
    name: 'viewProductCode',
    path: '/link/:id',
    component: AdvertiserProductCodeView,
    meta: {
      requiresAuth: true
    },
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.hideNav)) {
    store.state.hideNav = true;
  } else {
    store.state.hideNav = false;
  }

  // Only for authenticated users
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') !== null) {
      // Pass the JWT to the state
      if (!store.getters.jwtToken.ID) {
        store.commit('jwtToken', localStorage.getItem('jwt'))
      }

      // Validate expiry time for JWT token
      if (store.getters.jwtToken.exp > (Math.floor(Date.now() / 1000))) {
        next();
        return;
      } else {
        localStorage.removeItem('jwt');
      }
    }

    // Redirect to login form
    next({
      path: '/',
      params: { nextUrl: to.fullPath }
    })
    return;
  } else if(to.matched.some(record => record.meta.guestOnly)) {
    if (localStorage.getItem('jwt') !== null) {
      next({
        path: '/dashboard',
        params: { nextUrl: to.fullPath }
      })
      return;
    }
  }

  next();
});

export default router;
