<template>
  <v-row justify="">
    <v-dialog v-model="dialog" persistent no-click-animation="true" retain-focus="true" hide-overlay="true" max-width="450px" content-class="login-dialog">
      <v-card class="pa-10">
        <v-card-title>
          <span class="headline">Sign In</span>
        </v-card-title>
        <v-card-text>

          <v-alert type="error" v-model="showError" dismissible>
            Your credentials were not recognised.
          </v-alert>

          <v-form ref="form" @submit="submit">
            <v-text-field
                    v-model="email"
                    label="Email Address"
                    :rules="[rules.required]"
            ></v-text-field>

            <v-text-field
                    v-model="password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPass ? 'text' : 'password'"
                    name="input-10-1"
                    label="Password"
                    @click:append="showPass = !showPass"
            ></v-text-field>

            <v-btn
                    color="primary"
                    class="mt-6"
                    @click="submit"
                    type="submit"
                    block
            >
              Sign In
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
  <!--<v-card
    class="mx-auto"
    style="max-width: 500px;"
  >
     <v-toolbar
      color="primary"
      cards
      dark
      flat
    >
      <v-card-title>Sign in</v-card-title>
    </v-toolbar>


  </v-card>-->
</template>

<script>
import api from './../helpers/Api'
import store from "../store";

export default {
  data() {
    return {
      dialog: true,
      form: {},
      showError: false,
      showPass: false,
      email: '',
      password: '',
      rules: {
        required: value => !!value || 'Required.',
        emailMatch: () => ('The email and password you entered don\'t match'),
      },
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        this.$store.dispatch('showLoadingDialog');
        api.auth(this.email, this.password).then(response => {
          localStorage.setItem("jwt", response.data.token)
          this.$store.commit('jwtToken', response.data.token)

          this.$router.push((this.$store.state.isAdvertiser ? '/orders' : '/links'))
        }).catch(error => this.showError = true).finally(response => {
          this.$store.dispatch('hideLoadingDialog')
        })
      }
    },
  },
};
</script>

<style>
  .login-dialog {
    margin-top: -15%;
  }
</style>
