<template>
  <div>
    <v-data-table
        :footer-props="{'items-per-page-options': []}"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        loading-text="Retrieving Integrations..."
        no-data-text="No Integrations Found"
        disable-sort
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
            <v-toolbar-title>Integrations</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn to="/setup" color="primary" dark class="mb-2">Setup Integrations</v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.type="{ item }">
        <span v-if="item.type === 1">Magento Product Data API</span>
        <span v-if="item.type === 2">Magento Orders API</span>
        <span v-if="item.type === 3">Payments API</span>
      </template>

      <template v-slot:item.actions="{ item }">
          <v-icon
                  small
                  class="mr-2"
                  @click="deleteIntegration(item)"
          >
              mdi-delete
          </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">Are you sure you want to delete this integration?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import api from './../helpers/Api'

export default {
    data: () => ({
        dialog: false,
        errorDialog: false,
        dialogDelete: false,
        deleteItem: null,
        total: 0,
        items: [],
        loading: true,
        options: {},
        headers: [
            {text: 'Type',value: 'type'},
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        defaultItem: {
            path: '',
            type: 1
        },
    }),
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
      },
      dialog (val) {
        val || this.close()
      },
    },
    methods: {
      close () {
        this.dialog = false
      },
      save () {
        this.loading = true;
        api.createDataSource(this.defaultItem.path, 1).then(response => {
          this.getDataFromApi()
          this.loading = false;
        }).catch(err => {
          this.loading = false;
          this.dialog = true;
          this.errorDialog = true;
        })
        this.close()
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            api.getIntegrationList(page).then(response => {
                this.items = response.data.data;
                this.total = response.data.total;
                resolve();
            }).catch(error => {
                this.items = [];
                reject();
            }).finally(response => {
                this.loading = false;
            });
        })
      },
      deleteIntegration(item) {
        this.dialogDelete = true;
        this.deleteItem = item;
      },
      deleteItemConfirm() {
        this.dialogDelete = false;
        this.loading = true;

        api.deleteIntegration(this.deleteItem.id).then(response => {
            this.getDataFromApi();
        }).finally(response => {
            this.loading = false;
        });
      }
    }
  }
</script>
