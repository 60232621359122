var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{'items-per-page-options': []},"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":"Retrieving Orders...","no-data-text":"No Orders Found","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Orders")]),_c('v-spacer'),_c('form',{attrs:{"action":"https://api.smartcheckout.co.uk/order/export/mine","method":"post","id":"exportForm"}},[_c('input',{attrs:{"type":"hidden","name":"jwt"},domProps:{"value":_vm.$store.getters.rawJwtToken}})]),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.exportOrders}},[_vm._v("Export Data")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD-MM-YYYY"))+" ")]}},{key:"item.publisher_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.publisher.name)+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.line_items[0].product_advertiser_identifier)+" ")]}},{key:"item.commission_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.line_items[0].commission_total)+" ("+_vm._s(item.line_items[0].commission_rate)+"%) ")]}},{key:"item.product_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.line_items[0].total)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }