<template>
  <div>
      <v-data-table
          :footer-props="{'items-per-page-options': []}"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          loading-text="Retrieving Advertisers..."
          no-data-text="No Advertisers Found"
          disable-sort
          class="elevation-1"
          @click:row="view"
      >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Advertisers</v-toolbar-title>
            </v-toolbar>
        </template>
    </v-data-table>
  </div>
</template>


<script>
import api from './../helpers/Api'

export default {
data () {
      return {
        total: 0,
        items: [],
        loading: true,
        options: {},
        headers: [
          { text: 'Name', value: 'name' },
          { text: '', value: 'data-table-expand' },
        ],
      }
    },
    computed: {
      expandedItem: function() {
        if (this.expanded.length == 1) {
          return this.expanded[0];
        }
        return {}
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
    },
    methods: {
      view(row) {
        this.$router.push({ name: 'viewAdvertiser', params: { id: row.id } });
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options

            api.getAdvertiserList(page).then(response => {
                this.items = response.data.data;
                this.total = response.data.total;
                resolve();
            }).catch(error => {
                this.items = [];
                reject();
            }).finally(response => {
                this.loading = false;
            });
        })
      },
    }
  }
</script>
