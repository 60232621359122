<template>
  <div>
    <v-data-table
      :footer-props="{'items-per-page-options': []}"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      loading-text="Retrieving Links..."
      no-data-text="No Links Found"
      disable-sort
      class="elevation-1"
      @click:row="view"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Links</v-toolbar-title>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>


<script>
  import api from './../helpers/Api'

  export default {
    data () {
      return {
        total: 0,
        items: [],
        loading: true,
        options: {},
        headers: [
          {
            text: 'Code',
            align: 'left',
            sortable: false,
            value: 'code',
          },
          { text: 'Product Id', value: 'product_id' }
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
    },
    methods: {
      view(row) {
        this.$router.push({ name: 'viewProductCode', params: { id: row.code } });
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options

          api.getProductCodeList(page).then(response => {
            this.items = response.data.data;
            this.total = response.data.total;
            resolve();
          }).catch(error => {
            this.items = [];
            reject();
          }).finally(response => {
            this.loading = false;
          });
        })
      },
    }
  }
</script>
