<template>
    <v-card max-width="500">
        <!-- <v-img :src="productImg" height="500px"></v-img> -->
        <v-card-title v-text="productName" />
        <v-card-subtitle v-text="advertiserName" />
        <v-spacer></v-spacer>
        <v-card-subtitle v-text="link" />
    </v-card>
</template>

<script>
    import api from './../helpers/Api'

    export default {
        data () {
            return {
                link: '',
                productCode: '',
                advertiserName: '',
                productName: '',
                productImg: ''
            }
        },
        mounted() {
            this.$store.dispatch('showLoadingDialog');
            api.getProductCode(this.$route.params.id).then(response => {
                this.link = 'https://smart-63817.web.app/' + response.data.code;
                this.productCode = response.data.code;
                this.advertiserName = response.data.advertiser.name;
                this.productName = response.data.product.name;
                this.productImg = response.data.product.image_url;
            }).catch(error => {

            }).finally(() => {
                this.$store.dispatch('hideLoadingDialog');
            });
        }
    }
</script>
