import Vue from 'vue';
import Vuex from 'vuex';
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hideNav: false,
    jwtToken: '',
    loggedIn: false,
    isAdvertiser: false,
    loaderDialog: false,
    errorMessage: '',
    showErrorMessage: false
  },
  mutations: {
    jwtToken (state, payload) {
      state.jwtToken = payload

      if (payload) {
        payload = VueJwtDecode.decode(state.jwtToken);
        if (payload.type == 2 || payload.type == 3) {
          state.loggedIn = true;
          state.isAdvertiser = (payload.type == 2);
          return;
        }
      }

      state.loggedIn = false;
      state.isAdvertiser = false;
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.loggedIn;
    },
    isAdvertiser: state => {
      return state.isAdvertiser;
    },
    jwtToken: state => {
      if (state.jwtToken) {
        return VueJwtDecode.decode(state.jwtToken);
      }
      return {
        id: 0,
        name: null,
        type: null,
        advertiserID: 0,
        advertiserName: null
      };
    },
    rawJwtToken: state => {
      return state.jwtToken
    }
  },
  actions: {
    showLoadingDialog({commit,state}) {
      state.loaderDialog = true;
    },
    hideLoadingDialog({commit,state}) {
      state.loaderDialog = false;
    },
    showErrorMessage({commit, state}, message) {
      state.showErrorMessage = !!message;
      state.errorMessage = message;
    }
  },
});
