<template>
  <v-app id="inspire">
    <v-main>
    <v-container>
      <v-row no-gutters>
        <v-col cols="3" id="navigation">
          <template
            v-if="!this.$store.state.hideNav"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">
                  Smart Checkout
                </v-list-item-title>
                <v-list-item-subtitle  v-if="this.$store.getters.isAdvertiser">
                  {{ this.$store.getters.jwtToken.advertiserName }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ this.$store.getters.jwtToken.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list rounded v-if="this.$store.getters.isAdvertiser">
              <v-list-item to="/orders" link>
                <v-list-item-action>
                  <v-icon>mdi-currency-usd</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Orders</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/products" link>
                <v-list-item-action>
                  <v-icon>mdi-format-list-bulleted-square</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Products</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/integrations" link>
                <v-list-item-action>
                  <v-icon>mdi-database-import</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Integrations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/profile" link>
                <v-list-item-action>
                  <v-icon>mdi-card-account-details</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/help" link>
                <v-list-item-action>
                  <v-icon>mdi-chat-question</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Help</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="" @click="logoutLink">
                <v-list-item-action>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list rounded v-if="!this.$store.getters.isAdvertiser">
              <v-list-item to="/links" link>
                <v-list-item-action>
                  <v-icon>mdi-link</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Links</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="/advertisers" link>
                <v-list-item-action>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Advertisers</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item to="" @click="logoutLink">
                <v-list-item-action>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-col>
        <v-col cols="9">
          <v-content>
            <v-container fluid>
              <router-view></router-view>
            </v-container>
          </v-content>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="this.$store.state.loaderDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-4">
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="this.$store.state.showErrorMessage" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">There was a problem</v-card-title>
        <v-card-text v-text="$store.state.errorMessage"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="hideErrorMessage">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true
  }),
  methods: {
    hideErrorMessage() {
      this.$store.dispatch('showErrorMessage', false);
    },
    logoutLink(e) {
      e.preventDefault();
      this.$store.dispatch('showLoadingDialog');
      localStorage.removeItem('jwt');
      setTimeout(function() {
        this.$store.dispatch('hideLoadingDialog');
        this.$router.push('logout')
      }.bind(this), 500);
    }
  }
}
</script>

<style>
  #inspire {
    background: #f9f9f9;
  }
  #navigation .v-list {
    background-color: transparent;
  }
</style>
