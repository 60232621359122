<template>
  <div>
    <v-data-table
      :footer-props="{'items-per-page-options': []}"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      loading-text="Retrieving Orders..."
      no-data-text="No Orders Found"
      disable-sort
      class="elevation-1"
    >
     <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Orders</v-toolbar-title>
        <v-spacer></v-spacer>
        <form action="https://api.smartcheckout.co.uk/order/export/mine" method="post" id="exportForm">
          <input type="hidden" name="jwt" :value="$store.getters.rawJwtToken" />
        </form>
        <v-btn @click="exportOrders" color="primary" dark class="mb-2">Export Data</v-btn>
      </v-toolbar>
     </template>

      <template v-slot:item.created_at="{ item }">
       {{ item.created_at | moment("DD-MM-YYYY") }}
      </template>
      <template v-slot:item.publisher_id="{ item }">
        {{ item.publisher.name }}
      </template>
      <template v-slot:item.product="{ item }">
        {{ item.line_items[0].product_advertiser_identifier }}
      </template>
      <template v-slot:item.commission_total="{ item }">
        {{ item.line_items[0].commission_total }} ({{ item.line_items[0].commission_rate }}%)
      </template>
      <template v-slot:item.product_value="{ item }">
        {{ item.line_items[0].total }}
      </template>
   </v-data-table>
  </div>
</template>


<script>
import api from './../helpers/Api'

export default {
data () {
      return {
        total: 0,
        items: [],
        loading: true,
        options: {},
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'advertiser_order_id',
          },
          { text: 'Transaction ID', value: 'transaction_id' },
            { text: 'Product', value: 'product' },
            { text: 'Publisher', value: 'publisher_id' },
            { text: 'Order Value', value: 'amount_total' },
            { text: 'Product Value', value: 'product_value' },
            { text: 'Commission', value: 'commission_total' },
            { text: 'Date', value: 'created_at' }
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
    },
    methods: {
      exportOrders() {
        document.getElementById("exportForm").submit();
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options

          api.getOrdersList(page).then(response => {
            this.items = response.data.data;
            this.total = response.data.total;
            resolve();
          }).catch(error => {
              this.items = [];
              reject();
          }).finally(response => {
              this.loading = false;
          });
        })
      },
    }
  }
</script>
